import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient } from './utils/Apollo-client.utils';
import { createBrowserHistory } from 'history';
import { Router } from "react-router-dom";
import { PublicLayout } from './layouts/public';
import { withAuthContext } from './context/auth';
import { AuthContext } from './context/auth/Auth.Provider';
import { PrivateLayout } from './layouts/private';

export const history = createBrowserHistory();

function App() {
  const authContext = React.useContext(AuthContext);

  return (
    <ApolloProvider client={ApolloClient()}>
      <Router history={history}>
        {
          authContext.isLogged 
          ? <PrivateLayout />
          : <PublicLayout />
        }
      </Router>
    </ApolloProvider>
  );
}

export default withAuthContext(App);
