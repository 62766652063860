export const MenuLinks = [
    /*
     * hide this for now
    {
        name: 'Products',
        link: '',
    },
     */
    {
        name: 'Meditations',
        link: '',
        // link: '/meditations',
    },
    {
        name: 'Users',
        link: '/users',
    },
    /*
     * hide this for now
    {
        name: 'Discounts',
        link: '/discounts',
    },
     */
    {
        name: 'Home',
        link: '/home',
    },
    {
        name: 'Authors',
        link: '/authors',
    },
    {
        name: 'Categories',
        link: '/meditation-categories',
    },
    {
        name: 'Courses',
        link: '/courses',
    },
];
