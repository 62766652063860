import { IAuthContext } from "./interfaces/IAuthContext";
import { AuthActionUnion, AuthActionKeys } from "./Auth.Actions";
import { getAllTokens } from "../../utils/Auth.utils";

const tokens = getAllTokens();

export const INITIAL_STATE: IAuthContext = {
  isLogged: !!tokens,
  isLoading: false,
  token: tokens
};

export const AuthReducers = (state = INITIAL_STATE, action: AuthActionUnion): IAuthContext => {
  switch(action.type) {
    case AuthActionKeys.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLogged: true,
        token: action.payload,
        errorMessage: undefined,
      };
    case AuthActionKeys.LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };

    case AuthActionKeys.REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: undefined,
      };
    case AuthActionKeys.REGISTER_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };

    case AuthActionKeys.RESET_PASS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: undefined,
      };
    case AuthActionKeys.RESET_PASS_FAILED:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };

    case AuthActionKeys.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        errorMessage: undefined,
      }
    default:
      return state;
  }
}