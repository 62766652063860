import React, { FC } from 'react';
import { MenuLinks } from './Menu.Links';
import { history } from '../../../../App';

import styles from './Menu.module.scss';

export const Menu: FC = () => (
  <div className={styles['Menu']}>
    {
      MenuLinks.map(x => (
        <div key={`menu_${x.name}`} className={styles['item']} onClick={() => history.push(x.link)}>
          {x.name}
        </div>
      ))
    }
  </div>
);