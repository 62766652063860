import { ILoginResponse } from './interfaces/responses/ILoginResponse';
import { createAction, Action, ActionsUnion } from '../../utils/Action.utils';
import { IRegisterRequest } from './interfaces/request/IRegisterRequest';
import { IPasswordChange } from './interfaces/request/IPasswordChange';

export enum AuthActionKeys {
  LOGIN_SUCCESS = '[AUTH] LOGIN_SUCCESS',
  LOGIN_FAILED = '[AUTH] LOGIN_FAILED',

  REGISTER_SUCCESS = '[AUTH] REGISTER_SUCCESS',
  REGISTER_FAILED = '[AUTH] REGISTER_FAILED',

  RESET_PASS_SUCCESS = '[AUTH] RESET_PASS_SUCCESS',
  RESET_PASS_FAILED = '[AUTH] RESET_PASS_FAILED',

  SET_LOADING = '[AUTH] SET_LOADING'
}

export const AuthActions = {
  loginSuccess: (data: ILoginResponse): LoginSuccessAction => createAction(AuthActionKeys.LOGIN_SUCCESS, data),
  loginError: (msg: string[]): LoginFailAction => createAction(AuthActionKeys.LOGIN_FAILED, msg),

  registerSuccess: (body: IRegisterRequest): RegisterSuccessAction => createAction(AuthActionKeys.REGISTER_SUCCESS, body),
  registerError: (err: string[]): RegisterFailAction => createAction(AuthActionKeys.REGISTER_FAILED, err),

  resetPasswordSuccess: (body: IPasswordChange): ResetPasswordSuccessAction => createAction(AuthActionKeys.RESET_PASS_SUCCESS, body),
  resetPasswordError: (err: string[]): ResetPasswordFailAction => createAction(AuthActionKeys.RESET_PASS_FAILED, err),

  setLoading: (isLoading: boolean): StartLoadingAction => createAction(AuthActionKeys.SET_LOADING, isLoading),
}

export type AuthActionUnion = ActionsUnion<typeof AuthActions>;

export type LoginSuccessAction = Action<AuthActionKeys.LOGIN_SUCCESS, ILoginResponse>;
export type LoginFailAction = Action<AuthActionKeys.LOGIN_FAILED, string[]>;

export type RegisterSuccessAction = Action<AuthActionKeys.REGISTER_SUCCESS, IRegisterRequest>;
export type RegisterFailAction = Action<AuthActionKeys.REGISTER_FAILED, string[]>;

export type ResetPasswordSuccessAction = Action<AuthActionKeys.RESET_PASS_SUCCESS, IPasswordChange>;
export type ResetPasswordFailAction = Action<AuthActionKeys.RESET_PASS_FAILED, string[]>;

export type StartLoadingAction = Action<AuthActionKeys.SET_LOADING, boolean>;
