import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Header } from '../../components/header';

const Products = React.lazy(() => import('../../containers/products'));
const Discounts = React.lazy(() => import('../../containers/discounts'));
const Meditations = React.lazy(() => import('../../containers/meditations'));
const MeditationsDetail = React.lazy(() => import('../../containers/meditations-detail'));
const Users = React.lazy(() => import('../../containers/users'));
const Home = React.lazy(() => import('../../containers/home'));
const Authors = React.lazy(() => import('../../containers/authors'));
const MeditationCategories = React.lazy(() => import('../../containers/categories'));
const Courses = React.lazy(() => import('../../containers/courses'));
const CoursesModules = React.lazy(() => import('../../containers/courses-module'));
const ModulesLessons = React.lazy(() => import('../../containers/courses-modules-lessons'));

export const PrivateLayout: FC = () => {
    return (
        <>
            <Header />
            <React.Suspense fallback={<p>Loading...</p>}>
                <Switch>
                    <Route path="/meditations/:id" component={MeditationsDetail} />
                    <Route path="/meditations" component={Meditations} />
                    <Route path="/home" component={Home} />
                    <Route path="/users" component={Users} />
                    <Route path="/discounts" component={Discounts} />
                    <Route path="/authors" component={Authors} />
                    <Route path="/meditation-categories" component={MeditationCategories} />
                    <Route exact path="/courses" component={Courses} />
                    <Route exact path="/courses/module/:name/:id" component={CoursesModules} />
                    <Route exact path="/courses/module/lesson/:idCurso/:idModulo/:name" component={ModulesLessons} />
                    {/* <Route exact path="/" component={Products}/> */}
                    <Route exact path="/" component={Meditations}/>
                    <Route exact path="/products" component={Products}/>
                </Switch>
            </React.Suspense>
        </>
    );
};
